/* styles.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #fafafa;
}

body {
  background-color: #fafafa;
  font-family: Helvetica Neue;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.01em;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  color: #111;
}

.header, .footer, .button-content {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  z-index: 2;
}

.header {
  top: 10px;
}

.footer {
  bottom: 10px;
}

.button-content {
  bottom: 60px;
  font-size: 1.5em;
}

.header-text, .footer-text {
  display: inline-block;
}

a {
  position: relative;
  color: #333;
  text-decoration: none;
}

a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #111;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.left-sidebar {
  position: fixed;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}

.right-sidebar {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.left-sidebar-text, .right-element{
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: inherit;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}