.garments {
    position: relative;
    height: 100vh;
  }
  

.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}