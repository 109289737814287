/* ephemera.css */

.ephemera {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.slider {
    margin: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slick-slide {
    text-align: center;
    outline: none;
}

.slick-image {
    max-width: 100%;
    max-height: 50vh;
    width: auto;
    height: auto;
    margin: 0 auto;
}

.image-text {
    margin-top: 10px;
}

.slick-dots li {
    margin: -6px 0px !important;
}
